import React, { Fragment, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
	Checkbox,
	InputNumber,
	message,
	TimePicker,
	Select,
	Button,
	Popconfirm,
} from "antd";
import { useLocation } from "react-router-dom";
import {
	TaskTypeEnum,
	DaysTypeEnum,
	ScheduleTypeEnum,
	WorkTypeEnum,
} from "../utils/enums";
import {
	getAuthTaskScheduler,
	getTaskSchedules,
	updateSchedule,
	getAuthVendorTask,
	generateNow,
	getRuntimeById,
} from "../services/baseAPI";
import { setTaskSchedules } from "../context/actions/taskScheduleAction";
import DynamicTable from "../components/DynamicTable";
import TableTag from "../components/TableTag";
import moment from "moment";
import AddScheduleForm from "./AddScheduleForm";
import WorkItemModal from "./WorkItemModal";
import {
	convertMinutesToDaysAndTime,
	convertDaysAndTimeToMinutes,
} from "../utils/dateUtils";
import { envNameKey } from "../utils/envNameUtils";
const { Option } = Select;

/*eslint eqeqeq: "off"*/
const TaskSchedulerTabs = () => {
	const schedules = useSelector((state) => state.allTaskScheduler.schedules);
	const { search } = useLocation();
	const searchParams = new URLSearchParams(search);
	const token = searchParams.get("token");
	const companyId = searchParams.get("companyId");
	const envName = envNameKey(searchParams.get("envName"));
	const isCustomerReady = searchParams.get("customerReady");
	const [companyName, setCompanyName] = useState("");
	const [isLoading, setIsLoading] = useState(true);
	const [editKey, setEditKey] = useState("");
	const [isGenerating, setIsGenerating] = useState(false);
	const dispatch = useDispatch();
	const [itemCount, setItemCount] = useState(0);

	const getHeader = () => {
		const isReady = isCustomerReady === "1";
		const header = [
			{
				title: "Run Offset",
				dataIndex: "runOffsetMinutes",
				key: "runOffsetMinutes",
				width: 200,
				render: (item, record) => (
					<Fragment>
						{isReady && editKey === record.taskScheduleId ? (
							<div className="run-offset-fields">
								<InputNumber
									defaultValue={record.runOffsetFullDays}
									max={31}
									min={-31}
									onPressEnter={onPressEnterField("runOffsetFullDays", record)}
								/>
								<span>day/s at</span>

								<TimePicker
									defaultValue={moment(
										`${Math.abs(record.runOffsetRemainderHours)}:${
											record.runOffsetRemainderMinutes
										}`,
										["HH.mm"]
									)}
									use12Hours
									format="h:mm"
									onChange={onChangeTime(record)}
									showNow={false}
								/>
							</div>
						) : (
							convertMinutesToDaysAndTime(
								// item
								record.runOffsetFullDays,
								Math.abs(record.runOffsetRemainderHours),
								record.runOffsetRemainderMinutes
							)
						)}
					</Fragment>
				),
			},
			{
				title: "Days Type",
				dataIndex: "daysType",
				key: "daysType",
				width: 200,
				render: (item, record) => (
					<Fragment>
						{isReady && editKey === record.taskScheduleId ? (
							<Select
								defaultValue={item}
								onChange={onChangeSelector("daysType", record)}
							>
								{DaysTypeEnum.getKeyValueList().map((item) => (
									<Option key={item.id} value={item.id}>
										{item.value}
									</Option>
								))}
							</Select>
						) : (
							<TableTag
								color={DaysTypeEnum.getColorFromKey(item)}
								background={DaysTypeEnum.getBgColorFromKey(item)}
								name={DaysTypeEnum.getDscpFromKey(item)}
							/>
						)}
					</Fragment>
				),
			},
			{
				title: "Task Type",
				dataIndex: "taskType",
				key: "taskType",
				width: 200,
				render: (item) => (
					<Fragment>
						<TableTag
							color={TaskTypeEnum.getColorFromKey(item)}
							background={TaskTypeEnum.getBgColorFromKey(item)}
							name={TaskTypeEnum.getDscpFromKey(item)}
						/>
					</Fragment>
				),
			},
			{
				title: "Work Type",
				dataIndex: "workType",
				key: "workType",
				width: 200,
				render: (item) => (
					<Fragment>
						<TableTag
							color={WorkTypeEnum.getColorFromKey(item)}
							background={WorkTypeEnum.getBgColorFromKey(item)}
							name={WorkTypeEnum.getDscpFromKey(item)}
						/>
					</Fragment>
				),
			},
			{
				title: "Schedule Type",
				dataIndex: "scheduleType",
				key: "scheduleType",
				width: 200,
				render: (item) => (
					<Fragment>
						<TableTag
							color={ScheduleTypeEnum.getColorFromKey(item)}
							background={ScheduleTypeEnum.getBgColorFromKey(item)}
							name={ScheduleTypeEnum.getDscpFromKey(item)}
						/>
					</Fragment>
				),
			},
			{
				title: "Active",
				dataIndex: "active",
				key: "active",
				align: "center",
				width: 100,
				render: (item, record) => {
					let defaultActive = item;
					return (
						<Fragment>
							{isReady && editKey === record.taskScheduleId ? (
								<Checkbox
									defaultChecked={defaultActive == "1" ? true : false}
									onChange={onChangeCheckbox("active", record)}
								/>
							) : (
								<span>
									{defaultActive == "1"
										? "Yes"
										: defaultActive == "0"
										? "No"
										: item}
								</span>
							)}
						</Fragment>
					);
				},
			},
			{
				title: "Latest Period",
				dataIndex: "latestPeriod",
				key: "latestPeriod",
				width: 200,
			},
			{
				title: "Previous Run",
				dataIndex: "prevRuntime",
				key: "prevRuntime",
				width: 250,
				render: (item, record) => (
					<Fragment>
						<WorkItemModal
							runTimeDate={item && moment(item).format("YYYY-MM-DD h:mm A")}
							runTimeId={record.previousRunId}
						/>
					</Fragment>
				),
			},
			{
				title: "Current Run",
				dataIndex: "currentRuntime",
				key: "currentRuntime",
				width: 250,
				render: (item, record) => (
					<Fragment>
						<WorkItemModal
							runTimeDate={item && moment(item).format("YYYY-MM-DD h:mm A")}
							runTimeId={record.nextRunId}
						/>
					</Fragment>
				),
			},
			{
				title: "Next Run",
				dataIndex: "nextRunTime",
				key: "nextRunTime",
				width: 250,
				render: (item) => (
					<>{item && moment(item).format("YYYY-MM-DD h:mm A")}</>
				),
			},
		];

		if (!isReady) {
			header.push({
				title: "",
				dataIndex: "",
				key: "generate",
				width: 250,
				render: (record) => (
					<Fragment>
						{record.taskType !== "all" && (
							<Popconfirm
								placement="left"
								title={
									"Do you want to proceed? This will create new transactions immediately."
								}
								onConfirm={onClickGenerate(record.taskScheduleId)}
								okText="Yes"
								cancelText="No"
							>
								<Button
									size="large"
									loading={editKey === record.taskScheduleId && isGenerating}
								>
									Generate Transaction
								</Button>
							</Popconfirm>
						)}
					</Fragment>
				),
			});
		}

		return header;
	};

	const getCurrentRuntime = async (runTimeId) => {
		try {
			const response = runTimeId && (await getRuntimeById(runTimeId));
			return response;
		} catch {
			return "";
		}
	};

	const fetchSchedules = async (companyId, endDate, startDate, size, page) => {
		const response = await getTaskSchedules(
			companyId,
			endDate,
			startDate,
			size,
			page,
			envName
		).catch((err) => {
			console.log(err);
		});

		if (response) {
			setIsLoading(false);
			const content = response.data.content;
			setItemCount(content.length);

			let updated = await Promise.all(
				content.map(async (item) => {
					// item.currentRuntime = item.previousRunTime;
					// const timeNow = moment();
					// const duration =
					// 	item.currentRuntime &&
					// 	moment.duration(
					// 		moment(timeNow.format("YYYY-MM-DD h:mm A")).diff(
					// 			moment(item.currentRuntime).format("YYYY-MM-DD h:mm A")
					// 		)
					// 	);
					// const hours = duration && duration.asHours();
					// if (hours && hours >= 1) {
					// 	item.canActivate = true;
					// } else {
					// 	item.canActivate = false;
					// }

					const current = await getCurrentRuntime(item.nextRunId);
					const previous = await getCurrentRuntime(item.previousRunId);
					const prevtime = previous && previous.data;
					const currtime = current && current.data;
					return {
						...item,
						prevRuntime: prevtime && prevtime.runOnDateTime,
						currentRuntime: currtime && currtime.runOnDateTime,
					};
				})
			);

			let filtered = updated.filter((item) => item.workType !== "VendorTask");
			dispatch(setTaskSchedules(filtered));
		}
	};

	const getUserInfo = async () => {
		const response = await getAuthTaskScheduler(
			companyId,
			token,
			envName
		).catch((err) => {
			console.log(err);
		});
		if (response) {
			fetchSchedules(response.data.companyId);
			setCompanyName(response.data.companyName);
		}
	};

	const onPressEnterField = (key, row) => (e) => {
		let value = e.target.value;
		let convertTime = moment(
			`${Math.abs(row.runOffsetRemainderHours)}:${
				row.runOffsetRemainderMinutes
			}`,
			["HH.mm"]
		);
		let totalConvertedMins = convertDaysAndTimeToMinutes(value, convertTime);

		let data = {
			runOffsetMinutes: totalConvertedMins,
			envName: envName,
		};
		row.runOffsetFullDays = value;
		updateSchedule(row.taskScheduleId, data).then((res) => {
			if (res && res.status === 200) {
				message.success("Record was successfully updated.");
				fetchSchedules(companyId);
			} else {
				message.error("Error in updating the record.");
			}
		});
	};

	const onChangeTime = (row) => (time) => {
		let convertTime = moment(time);
		let data = {
			runOffsetMinutes: convertDaysAndTimeToMinutes(
				row.runOffsetFullDays,
				convertTime
			),
			envName: envName,
		};

		row.runOffsetRemainderHours = convertTime.hour();
		row.runOffsetRemainderMinutes = convertTime.minute();

		updateSchedule(row.taskScheduleId, data).then((res) => {
			if (res && res.status === 200) {
				message.success("Record was successfully updated.");
				fetchSchedules(companyId);
			} else {
				message.error("Error in updating the record.");
			}
		});
	};

	const onChangeCheckbox = (key, row) => (e) => {
		let value = e.target.checked;
		row[key] = value ? "1" : "0";

		let data = {
			[key]: value,
			envName: envName,
		};
		updateSchedule(row.taskScheduleId, data).then((res) => {
			if (res && res.status === 200) {
				message.success("Record was successfully updated.");
			} else {
				message.error("Error in updating the record.");
			}
		});
	};

	const onChangeSelector = (key, row) => (value) => {
		row[key] = value;
		let data = {
			[key]: value,
		};

		updateSchedule(row.taskScheduleId, data).then((res) => {
			if (res && res.status === 200) {
				message.success("Record was successfully updated.");
				fetchSchedules(companyId);
			} else {
				message.error("Error in updating the record.");
			}
		});
	};

	const onClickGenerate = (id) => async () => {
		setIsGenerating(true);
		const response = await generateNow(id);

		if (response && response.status === 200) {
			message.success("Generated successfully.");
			fetchSchedules(companyId);
			setIsGenerating(false);
		} else {
			message.error("Error generating.");
			fetchSchedules(companyId);
			setIsGenerating(false);
		}
	};

	useEffect(() => {
		getAuthVendorTask(companyId, token);
		getUserInfo(); // eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<Fragment>
			<div className="sched-table-wrapper">
				{itemCount < 3 && (
					<AddScheduleForm
						token={token}
						companyId={companyId}
						list={schedules}
						envName={envName}
						companyName={companyName}
					/>
				)}

				<DynamicTable
					data={schedules}
					columnHeader={getHeader()}
					loading={isLoading}
					onRow={(record) => {
						return {
							onDoubleClick: () => {
								setEditKey(record.taskScheduleId);
							},

							onClick: () => {
								if (editKey != record.taskScheduleId) {
									setEditKey("");
								}
							},
						};
					}}
					pagination={false}
				/>
			</div>
		</Fragment>
	);
};
export default TaskSchedulerTabs;
